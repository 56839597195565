

















































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { get, set } from "idb-keyval";
import { DateTime } from "luxon";

interface Guild {
  id: string;
  name: string;
  tag: string;
  memberCount: number;
  recruitment: boolean;
  mainContact: null | string;
}

interface GuildStorage {
  date: string; // ISO8601
  guilds: Array<Guild>;
}

@Component({})
export default class Gilden extends Vue {
  guilds: Array<Guild> = [];
  loading = true;

  async created() {
    // Try to get guilds from cache
    let guildStorage: GuildStorage | undefined = await get("gw2mists-guilds");
    if (!guildStorage) {
      guildStorage = await this.fetchData();

      await set("gw2mists-guilds", guildStorage);
    } else {
      // Refresh cache if necessary
      const oldDate = DateTime.fromISO(guildStorage.date);
      if (oldDate.diff(DateTime.local()).days > 3) {
        guildStorage = await this.fetchData();
        await set("gw2mists-guilds", guildStorage);
      }
    }
    this.guilds = guildStorage.guilds;
    this.loading = false;
  }

  async fetchData(): Promise<GuildStorage> {
    const response = await axios.get(
      "https://api.gw2mists.com/guilds/overview"
    );

    const guildList: Guild[] = [];
    response.data
      .filter(
        (g: Record<string, string | number | boolean>) => g.world_id == 2201
      )
      .forEach((row: Record<string, string | number | boolean>) => {
        guildList.push({
          id: row.id as string,
          name: row.name as string,
          tag: row.tag as string,
          memberCount: row.member_count as number,
          recruitment: row.recruitment as boolean,
          mainContact: row.main_contact as string
        } as Guild);
      });
    return { date: new Date().toISOString(), guilds: guildList };
  }
}
